import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";
import {
  CallSvg,
  EmailSvg2,
  LocationSvg,
  ShopSvg,
  UserSvg2,
} from "../../../components/CommonSvg";
import { __t } from "../../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import {
  getTicketsRequest,
  getVenderListRequest,
} from "../../../redux/admin/support/action";
import { connect } from "react-redux";
import { getValueByKey } from "../../../utils/authUtils";
import Select from "react-select";
import { optionVendor, optionsIsuue, optionsPriority } from "./common";

const ServiceRequestModal = (props) => {
  const {
    initialValues,
    defaultValues,
    memberData,
    getTicketsRequest,
    onModalDismiss,
    getVenderListRequest,
    vendorList,
    onSubmitCall,
  } = props;
  const validator = useRef(new SimpleReactValidator());
  const hraMemberId = JSON.parse(getValueByKey("hraMemberId")) ?? "";
  const address = localStorage.getItem("mailingStreetAddress");
  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      values = {
        ...values,
        accountName: memberData?.accountName,
        hraMemberId: hraMemberId,
        storeNameRegular: memberData?.storeNameRegular,
        name: memberData?.name,
        email: memberData?.email,
        phoneNumber: memberData?.phoneNumber,
        showMessages: defaultValues,
      };
      if (defaultValues) {
        onSubmitCall();
      }
      onModalDismiss();
      getTicketsRequest(values);
    } else {
      validator.current.showMessages();
    }
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.background,
      color: state.data.color, // You can customize the text color here
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color, // You can customize the text color here
    }),
  };

  const [selectedOption, setSelectedOption] = useState(
    initialValues?.issue
      ? { value: initialValues.issue, label: initialValues.issue }
      : null
  );
  const [selectedPriority, setSelectedPriority] = useState(
    initialValues?.priority
      ? { value: initialValues.priority, label: initialValues.priority }
      : null
  );
  const [selectedVendor, setSelectedVendor] = useState(
    initialValues?.vendor
      ? { value: initialValues.vendor, label: initialValues.vendor }
      : null
  );
  useEffect(() => {
    getVenderListRequest();
  }, []);
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={formSubmit}>
        {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <div className="support-request-modal-list row">
              <div className="col-12 col-sm-12 col-md-6">
                <div className="support-request-modal-data">
                  <span className="support-icon-box">
                    <UserSvg2 />
                  </span>
                  <p>{memberData?.name ?? ""}</p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="support-request-modal-data">
                  <span className="support-icon-box">
                    <ShopSvg />
                  </span>
                  <p>{memberData?.storeNameRegular ?? ""}</p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="support-request-modal-data">
                  <span className="support-icon-box">
                    <CallSvg />
                  </span>
                  <p> {memberData?.phoneNumber ?? ""}</p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <div className="support-request-modal-data">
                  <span className="support-icon-box">
                    <EmailSvg2 />
                  </span>
                  <p> {memberData?.email ?? ""}</p>
                </div>
              </div>
              {address && (
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="support-request-modal-data">
                    <span className="support-icon-box">
                      <LocationSvg />
                    </span>
                    <p> {address}</p>
                  </div>
                </div>
              )}
              <div className="col-12">
                <FormGroup className=" mb-3">
                  <Field
                    type="text"
                    id="subject"
                    name="subject"
                    className="form-control"
                    placeholder="subject"
                    disabled={defaultValues}
                  />
                  <Label className="form-label" htmlFor="subject">
                    Subject
                  </Label>
                  {validator.current.message(
                    __t("validations.support.subject"),
                    values.subject,
                    "required",
                    {
                      messages: {
                        required: __t("validations.support.subject"),
                      },
                    }
                  )}
                </FormGroup>
              </div>
              <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                <FormGroup className=" mb-3">
                  <Select
                    value={selectedOption}
                    menuPlacement="auto"
                    onChange={(selected) => {
                      setSelectedOption(selected);
                      setFieldValue("issue", selected?.value);
                    }}
                    options={optionsIsuue}
                    className="form-control selectbox"
                    isClearable={true}
                    isDisabled={defaultValues}
                    id="issue"
                    name="issue"
                  />
                  <Label className="form-label" htmlFor="issue">
                    Category
                  </Label>
                  {validator.current.message(
                    __t("validations.support.issue"),
                    values.issue,
                    "required",
                    {
                      messages: {
                        required: __t("validations.support.issue"),
                      },
                    }
                  )}
                </FormGroup>
              </div>
              <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                <FormGroup className=" mb-3">
                  <Select
                    value={selectedVendor}
                    menuPlacement="auto"
                    onChange={(selected) => {
                      setSelectedVendor(selected);
                      setFieldValue("vendor", selected?.value);
                    }}
                    options={[
                      ...vendorList,
                      { value: "HRA Accounting", label: "HRA Accounting" },
                      {
                        value: "Tennessee Valley Ice",
                        label: "Tennessee Valley Ice",
                      },
                    ]}
                    className="form-control selectbox"
                    isClearable={true}
                    id="vendor"
                    name="vendor"
                    isDisabled={
                      initialValues?.vendor && defaultValues ? true : false
                    }
                  />
                  <Label className="form-label" htmlFor="vendor">
                    Vendor
                  </Label>
                  {validator.current.message(
                    __t("validations.support.vendor"),
                    values.vendor,
                    "required",
                    {
                      messages: {
                        required: __t("validations.support.vendor"),
                      },
                    }
                  )}
                </FormGroup>
              </div>
              <div className="col-12 col-md-12 col-lg-4 col-xl-4">
                <FormGroup className=" mb-3">
                  <Select
                    value={selectedPriority}
                    menuPlacement="auto"
                    onChange={(selected) => {
                      setSelectedPriority(selected);
                      setFieldValue("priority", selected?.value);
                    }}
                    options={optionsPriority}
                    className={
                      defaultValues
                        ? "field_disabled form-control selectbox"
                        : "form-control selectbox"
                    }
                    isClearable={true}
                    isDisabled={defaultValues}
                    styles={colourStyles}
                    id="priority"
                    name="priority"
                  />
                  <Label className="form-label" htmlFor="priority">
                    Priority
                  </Label>
                  {validator.current.message(
                    __t("validations.support.priority"),
                    values.priority,
                    "required",
                    {
                      messages: {
                        required: __t("validations.support.priority"),
                      },
                    }
                  )}
                </FormGroup>
              </div>

              <div className="col-12">
                <FormGroup className=" mb-3">
                  <Field
                    component="textarea"
                    id="description"
                    name="description"
                    className="form-control"
                    placeholder="Description Of Request"
                    rows={3}
                    disabled={defaultValues && initialValues?.description}
                  />
                  <Label className="form-label" htmlFor="description">
                    Description Of Request
                  </Label>
                  {validator.current.message(
                    __t("validations.support.description"),
                    values.description,
                    "required",
                    {
                      messages: {
                        required: __t("validations.support.description"),
                      },
                    }
                  )}
                </FormGroup>
              </div>
            </div>

            <div className="btn-box">
              <button
                color="primary"
                className="btn btn-outline-secondary me-3"
                title="Cancel"
                type="button"
                onClick={() => onModalDismiss()}
              >
                Cancel
              </button>

              <Button
                color="primary"
                className="btn btn-primary"
                title="Submit"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
const mapStateToProps = ({ support }) => {
  let vendorList =
    support.vendorList?.list?.length > 0
      ? support.vendorList.list.map((data) => {
          return {
            label: data.name,
            value: data.name,
          };
        })
      : [];

  return { vendorList };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTicketsRequest: (data) => dispatch(getTicketsRequest(data)),
    getVenderListRequest: (data) => dispatch(getVenderListRequest(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceRequestModal);
