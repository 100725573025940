import { Field, Form, Formik } from "formik";
import { Button, CardBody, FormGroup, FormText, Label } from "reactstrap";
import dashboardButton from "../../../assets/images/vendor_partners.webp";
import { DeleteSvg, ThumbnailSvg } from "../../../components/CommonSvg";
import { connect } from "react-redux";
import { createDashboardMenuRequest } from "../../../redux/admin/dashboard/action";
import { useEffect, useRef, useState } from "react";
import { handleSingleFileUpload } from "../../../helper/imageSingalHelper";
import { commonConfirmBox } from "../../../helper/messagehelpers";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import ReactSwitch from "react-switch";

const AddMenu = ({ createDashboardMenu, onModalDismiss, isEditModal }) => {
  const [imageFile, setImageFile] = useState();
  const [imageFileData, setImageFileData] = useState(null);
  const validator = useRef(new SimpleReactValidator());
  const handleSubmit = async (value) => {
    if (validator.current.allValid()) {
      const formData = new FormData();
      if (isEditModal?.id) {
        formData.append("id", isEditModal?.id);
        formData.append("isActive", value.isActive);
      }
      formData.append("menuImage", value.menuImage);
      formData.append("label", value.title);
      formData.append("menuLink", value.menuLink);

      createDashboardMenu(formData);
      onModalDismiss();
    } else {
      validator.current.showMessages();
    }
  };
  const fileInputRef = useRef(null);
  const clearFileInput = () => {
    fileInputRef.current.value = "";
  };
  useEffect(() => {
    if (isEditModal?.menuImage) {
      setImageFileData({ imageUrl: isEditModal.menuImage });
    }
  }, [isEditModal?.menuImage]);

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          title: isEditModal?.label || "",
          menuLink: isEditModal?.menuLink || "",
          menuImage: isEditModal?.menuImage || "",
          isActive: isEditModal?.isActive || false,
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="mt-3">
            <CardBody className="add-image">
              <div className="dropzone dropzone_img thumbnail-dropzone">
                {imageFileData && (
                  <>
                    <div className="promotion_img dashboard-drag-img">
                      <img
                        alt="Logo"
                        className="logo_main dashboard-img-inner"
                        src={imageFileData?.imageUrl}
                      />
                      <Button
                        onClick={async () => {
                          const response = await commonConfirmBox(
                            "Do you want to remove this image?"
                          );
                          if (response) {
                            setFieldValue("menuImage", null);
                            setImageFileData("");
                            setImageFile("");
                            clearFileInput();
                          }
                        }}
                        className="delete-dashboard-button"
                      >
                        <DeleteSvg />
                      </Button>
                    </div>
                  </>
                )}
                <label
                  htmlFor="menuImage"
                  className="dropzone-container dropzone-icon"
                >
                  <div className="file-icon">
                    <ThumbnailSvg />
                  </div>
                  <div className="dropzone-title text-center">
                    Accepted file types : .png
                    <small className="w-100 d-inline-block text-center">
                      Image size upto 1MB
                    </small>
                  </div>
                  <input
                    id="menuImage"
                    name="menuImage"
                    type="file"
                    accept="image/*"
                    className="form-control"
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleSingleFileUpload(
                        setImageFile,
                        setFieldValue,
                        "menuImage",
                        setImageFileData,
                        e,
                        true
                      );
                    }}
                  />
                </label>
                {validator.current.message(
                  __t("validations.common.image"),
                  values.menuImage,
                  "required",
                  {
                    messages: {
                      required: __t("validations.common.image"),
                    },
                  }
                )}
              </div>
              <div className="w-100">
                <FormGroup>
                  <Field
                    type="title"
                    id="title"
                    name="title"
                    className="form-control"
                    placeholder="Title"
                  />
                  <Label className="form-label" htmlFor="title">
                    Title
                    <span className="text-danger required">*</span>
                  </Label>
                  {validator.current.message(
                    __t("validations.promotion.title"),
                    values.title,
                    "required",
                    {
                      messages: {
                        required: __t("validations.promotion.title"),
                      },
                    }
                  )}
                </FormGroup>
                <FormGroup>
                  <Field
                    type="text"
                    id="menuLink"
                    name="menuLink"
                    className="form-control"
                    placeholder="menuLink"
                  />
                  <Label className="form-label" htmlFor="menuLink">
                    URL
                    <span className="text-danger required">*</span>
                  </Label>
                  <FormText className="text-sm">
                    <small>Example of linking to an inner page: "/admin/pagename"</small>
                  </FormText>
                  {validator.current.message(
                    __t("validations.common.link"),
                    values.menuLink,
                    "required",
                    {
                      messages: {
                        required: __t("validations.common.link"),
                      },
                    }
                  )}
                </FormGroup>
                {isEditModal?.id && (
                  <>
                    <FormGroup className="status-switch">
                      <Label htmlFor="menuLink">
                        Status
                      </Label>
                      <ReactSwitch
                        className={`react-switch justify-content-center switch-sm ${
                          values.isActive && "isActive"
                        }`}
                        onChange={async () => {
                          const response = await commonConfirmBox(
                            values.isActive
                              ? "Do you want to deactivate this menu?"
                              : "Do you want to activate this menu?",
                            undefined,
                            undefined
                          );
                          if (response) {
                            setFieldValue("isActive", !values?.isActive);
                          }
                        }}
                        type="checkbox"
                        id="custom-switch"
                        checked={values.isActive}
                      />
                    </FormGroup>
                  </>
                )}
                <div className="dashboard-model-button">
                  <Button
                    type="button"
                    color="secondary"
                    className="float-end"
                    onClick={onModalDismiss}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" className="float-end">
                    Submit
                  </Button>
                </div>
              </div>
            </CardBody>
          </Form>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = ({ dashboardReducer }) => {
  return {
    popUp: dashboardReducer.DashboardMenu?.status
      ? dashboardReducer.DashboardMenu.status
      : false,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createDashboardMenu: (data) => {
      dispatch(createDashboardMenuRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
